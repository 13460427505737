/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react';
import { BenefictsAndWhoDifferent, ExcusiveAttention, JsonLd, NormInfo, References, ServiceSteps, ServicesToOneClick } from '../../components';
import './styles.scss';
import { Helmet } from 'react-helmet';

const Nom055: React.FC = () => {
  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'NOM-055-SCFI-1994';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "@id": "https://www.cecsatrade.mx/nom-055-scfi-1994",
            "url": "https://www.cecsatrade.mx/nom-055-scfi-1994",
            "name": "NOM-055-SCFI-1994",
            "description": "Descubre la NOM-055-SCFI-1994, que establece las especificaciones de seguridad para la construcción y uso de materiales ignífugos en México. Asegura la protección contra incendios en edificaciones mediante el cumplimiento de estas normas cruciales.",
            "isPartOf": {
              "@id": "https://www.cecsatrade.mx/#/schema/WebSite/1"
            },
            "datePublished": "2023-08-17T08:08:40-06:00",
            "dateModified": "2023-08-17T08:43:03-06:00",
            "primaryImageOfPage": {
              "@id": "https://www.cecsatrade.mx/assets/055.svg"
            }
          }
        }
      />
      <Helmet>
        <meta
          name="description"
          content="Descubre la NOM-055-SCFI-1994, que establece las especificaciones de seguridad para la construcción y uso de materiales ignífugos en México. Asegura la protección contra incendios en edificaciones mediante el cumplimiento de estas normas cruciales."
        />
      </Helmet>
      <section className='norm-info'>

        <div className='sub-header'>
          <div className='sub-header__spacer'></div>
          <div className='sub-header__spacer'></div>
          {/* <a className='sub-header__button' href="https://blog.cecsatrade.mx/" target='_blank' rel="noreferrer">Blog</a> */}
          <a
            className='sub-header__email'
            href="mailto:asesoria@cecsatrade.mx"
            target="_blank">
            <img loading="lazy" src="/img/mail.svg" alt="Mail" />
            |
            Mail de CECSA
          </a>
        </div>
        <h1 className='norm-info__title'>NOM-055-SCFI-1994 MATERIALES IGNÍFUGOS  </h1>
        <div className='norm-info__header norm-info__header--055'>
          <button onClick={() => {
            const element = document.getElementById("benefits");

            if (element) {
              element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            }
          }}>Beneficios</button><a href='https://wa.me/message/YZR3TYWZRARDH1' target='_blank'>Quiero cumplir con la NOM</a>
          <button onClick={() => {
            const element = document.getElementById("preguntas-frecuentes");

            if (element) {
              element.scrollIntoView({ behavior: "smooth", inline: "nearest" });
            }
          }}>Preguntas frecuentes</button>
        </div>
        <div className='norm-info__image-container'>
          {/* <img className='norm-info__image-container__img' loading="lazy" src="/img/nom-003-asset.svg" alt="NOM 003 Logo" /> */}
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Sé el primero en saber...</h2>
            <br />
            <p>
              La Norma de <b>Materiales Retardantes e Inhibidores de Flama (NOM-055)</b> establece las especificaciones de seguridad y la información comercial para la correcta construcción y uso de estos materiales. Su objetivo es <b>garantizar un etiquetado</b> adecuado en envases y empaques, asegurando la protección del consumidor contra incendios en edificaciones.
              <br />
              <br />
              Esta <b>Norma Oficial Mexicana</b> es obligatoria para su comercialización dentro del territorio nacional ya sea para productos extranjeros o elaborados en México.
            </p>
          </div>
        </div>
        <div className='norm-info__image-container norm-info__image-container--black'>
          <div className='norm-info__image-container__img-container'>
            <img className='norm-info__image-container__img-container__image' loading="lazy" src="/img/extintores-1.png" alt="extintores-1" />
          </div>
          <div className='norm-info__image-container__text norm-info__image-container__text--center norm-info__image-container__text--small-margin'>
            <p style={{ textAlign: 'left' }}>
              Descubre la NOM-055-SCFI-1994, que establece las especificaciones de seguridad para la construcción y uso de materiales ignífugos en México. Asegura la protección contra incendios en edificaciones mediante el cumplimiento de estas normas cruciales.
            </p>
          </div>
        </div>
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Entrada en vigor</h2>
            <br />
            <p>
              La <b>NOM-055-SCFI-1994, Información comercial-Materiales retardantes y/o inhibidores de flama y/o ignífugos-Etiquetado</b>, fue publicada en el Diario Oficial de la Federación el 08 de diciembre de 1994,  pero entró en vigor  el 09 de diciembre de 1994.
            </p>
            <br />
            <a className='norm-info__image-container__anchorage' href="https://diariooficial.gob.mx/nota_detalle.php?codigo=4773710&fecha=08/12/1994#gsc.tab=0" target='_blank'>Más información de esta NOM</a>
          </div>
        </div><img className='norm-info__ellipse' src="/img/ellipse-black.svg" alt="ellipse" />
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Aplicación de la NOM-055</h2>
            <br />
            <p>La <b>NOM-055-SCFI-1994</b> es clave para promoverla seguridad tanto de las estructuras como de las personas que las ocupan.</p>
            <br />
            <p>
              <div className='norm-info__image-container__aplications-015'>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-055-1.svg" alt="Retardantes" />
                  <span><b>Retardantes</b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-055-2.svg" alt="Ignífugos" />
                  <span><b>Ignífugos</b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-055-3.svg" alt="Material retardante" />
                  <span><b>Material retardante</b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-055-4.svg" alt="Inhibidores de flama " />
                  <span><b>Inhibidores de flama </b></span>
                </div>
              </div>
            </p>
          </div>
        </div>
        <ExcusiveAttention />
        <div className='norm-info__image-container norm-info__image-container--blue-050'>
          <img className='norm-info__image-container__image-background-1' loading="lazy" src="/img/background-circle-blue-1.svg" alt="backghround 1" />
          <div className='norm-info__image-container__text'>
            <h2>La NOM-055-SCFI-1994  no aplica a:</h2>
            <br />
            <p>La presente <b>Norma Oficial Mexicana</b> no es aplicable a lo siguiente:</p>
            <br />
            <div className='norm-info__image-container__text__data-item-container-004'>
              <div className='norm-info__image-container__text__data-item' style={{ flexDirection: 'column' }}>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/not-aplication-055-1.svg" alt="Equipo de protección específico" />
                <span><b>Equipo de protección específico</b></span>
              </div>
              <div className='norm-info__image-container__text__data-item' style={{ flexDirection: 'column' }}>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/not-aplication-055-2.svg" alt="Materiales no ignífugos" />
                <span><b>Materiales no ignífugos</b></span>
              </div>
              <div className='norm-info__image-container__text__data-item' style={{ flexDirection: 'column' }}>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/not-aplication-055-3.svg" alt="Ropa desechable de protección contra el fuego" />
                <span><b>Ropa desechable de protección contra el fuego</b></span>
              </div>
            </div>
            <br />
          </div>
          <img className='norm-info__image-container__image-background-2' loading="lazy" src="/img/background-circle-blue-2.svg" alt="backghround 2" />
        </div>
        <div className='norm-info__image-container norm-info__image-container--black norm-info__image-container--margin norm-info__image-container--050' style={{ marginBottom: '30rem', overflow: 'visible', zIndex: '7' }}>
          <h2>DEFINICIONES</h2>
          <div className="norm-info__050-definitions__item">
            <span>Material retardante y/o inhibidor de flama</span>
            <p>Es el producto químico, que aplicado a otros materiales los protege del fuego o evita que éste se propague.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Muestreo</span>
            <p>Todas las muestras necesarias para las pruebas, seleccionadas del lote original y representativas del material. </p>
          </div>
          <p>Para las definiciones de los conceptos de envases, y embalajes, se toman las descritas en la Norma Oficial Mexicana NOM-030-SCFI.</p>
          <br />
          <h2>CONTENIDO DE LA ETIQUETA</h2>
          <br />
          <br />
          <p>Las etiquetas deben ofrecer información clara sobre el contenido, origen y características del producto en español, con opción de otros idiomas.</p>
          <br />
          <div className="norm-info__015-definitions__item norm-info__015-definitions__item--images">
            <img src="/img/055-logo-1.svg" alt="055-logo-1" />
            <img src="/img/055-logo-2.svg" alt="055-logo-2" />
            <img src="/img/055-logo-3.svg" alt="055-logo-3" />
            <img src="/img/055-logo-4.svg" alt="055-logo-4" />
          </div>
          <br />
          <b>“Nombre del fabricante y marca comercial”</b>
          <br />
          <b>Nombre genérico y nombre técnico o químico del material</b>
          <br />
          <div className="norm-info__050-definitions__item">
            <span>Instrucciones de uso</span>
            <p>Especificar cómo preparar y aplicar el producto, ya sea en la etiqueta o en un instructivo anexo.</p>
          </div>
          <div className="norm-info__015-definitions__item norm-info__015-definitions__item--column">
            <img src="/img/extintor-2.png" alt="extintor-2" />
            <div className="norm-info__015-definitions__item__container">
              <p>
                <br />
                <b>Advertencias de seguridad</b>
                <br />
                para el usuario y el medio ambiente
              </p>
            </div>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>País de origen</span>
            <p>Leyendas como "Hecho en…" o "Producto de…" para identificar el país de fabricación.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Fecha de caducidad</span>
            <p>Debe ser clara e inalterable, incluyendo la leyenda "Fecha de caducidad" o similar.</p>
          </div>
          <br />
          <p style={{ textAlign: 'center' }}>Esta información debe estar en un lugar visible del envase, empaque o embalaje del producto.</p>
          <img className='norm-info__050-definitions__footer' loading="lazy" src="/img/definitions-050-footer.svg" alt="footer" />
        </div>

        <BenefictsAndWhoDifferent
          title={'CERTIFICA TUS PRODUCTOS'}
          text={'Contactanos, nosotros te apoyamos en cada paso que debes realizar para cumplir conforme lo establecido de manera nacional en materia de comercial dentro de la industria.'}
        />
        <References
          items={[
            (
              <li>
                <img src="/img/list-item-055.png" alt="fire" />
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=4936602&fecha=06/11/2006#gsc.tab=0" target='_blank'>NOM-030-SCFI-2006</a>, Información Comercial- Declaración de cantidad en etiqueta.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-055.png" alt="fire" />
                <a href="https://www.gob.mx/cms/uploads/attachment/file/745343/nmx-z-12-2-1987_compressed__2_.pdf" target='_blank'>NMX-Z-12</a>, Muestreo para la inspección  por atributos
              </li>
            )
          ]}
        />
        <NormInfo
          DOFDate='08/12/1994'
          VigorDate='09/12/1994'
          responsible='SECRETARÍA DE ECONOMIA'
        />
        <div className='norm-info__questions' id='preguntas-frecuentes'>
          <h2 className="norm-info__container__sub-title">Preguntas frecuentes</h2>
          <ul className="accordion norm-info__container__not-transform-ul" id="accordion">
            <li className="accordion-item">
              <div id="heading1">
                <button className="accordion-button norm-info__container__accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                  ¿Cuáles son los aspectos clave de la Norma de Materiales Ignífugos?
                </button>
              </div>
              <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordion">
                <div className="accordion-body">
                  El objetivo de esta norma es establecer la información comercial de los materiales que deben ostentar para su comercialización en el territorio nacional:
                  <br />
                  <br />
                  - Retardantes
                  <br /> -Inhibidores de flama
                  <br /> -Ignífugos
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading2">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                  ¿Qué sanciones se aplican por incumplir la NOM-055-SCFI-1994?
                </button>
              </div>
              <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Las sanciones por incumplir la NOM-055-SCFI-1994, pueden incluir las siguientes medidas según lo establecido en la Ley Federal sobre Metrología y Normalización (LFMN) y las disposiciones legales aplicables:
                  <br /><br />
                  - Multas económicas: Las autoridades, como la Procuraduría Federal del Consumidor (PROFECO) o la Secretaría de Economía, pueden imponer multas que varían en función de la gravedad y la naturaleza de la infracción.
                  <br /><br />
                  Las multas pueden oscilar entre cientos a millas de veces la Unidad de Medida y Actualización (UMA), dependiendo de si se trata de una primera complicación o de reincidencias.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading3">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                  ¿Qué documentación se necesita para demostrar el cumplimiento de la NOM-055-SCFI-1994?
                </button>
              </div>
              <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Es necesario contar con la siguiente documentación:
                  <br /> -Certificado de Conformidad
                  <br /><br />
                  Un certificado certificado por un organismo de certificación acreditado por la Entidad Mexicana de Acreditación (EMA) y aprobada por la Dirección General de Normas (DGN). Este documento certifica que el material o producto ha sido sometido a las pruebas necesarias y cumple con los requisitos de la norma.
                  <br /><br />
                  En Cecsa te podemos proporcionar la documentación necesaria para que tu producto cuente   con todo para una correcta y  óptima comercialización.

                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading4">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                  ¿Qué puedo hacer si detienen mi producto en Aduana?
                </button>
              </div>
              <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordion">
                <div className="accordion-body">
                  La omisión o redacción de datos inexactos dentro de la NOM-055-SCFI-1994  son las principales causas para sanciones y retraso en el proceso aduanal.
                  <br /><br />
                  Un caso podría ser, la omisión de ciertos ingredientes en el listado comercial o en el etiquetado de productos.
                  <br />Los riesgos aquí, además del daño a los consumidores y medio ambiente, serían dos a nivel empresarial:
                  <br /><br />
                  - Retención de mercancías
                  <br /><br />
                  Durante  el proceso aduanal, en caso de incumplir con alguna de la NOMs por certificación o información comercial, se levanta un acta y detiene la salida de la mercancía según las leyes aduanales que especifique razón y tiempo para la resolución del problema.
                  <br /><br />
                  De no solucionarse en el plazo pactado, la mercancía pasará a manos de la aduana en México y fisco federal correspondiente, sin segundas oportunidades.
                  <br /><br />
                  - Cobro de multas
                  <br /><br />
                  En el caso de tener éxito y haber completado las regularizaciones de la NOM, al responsable se le cobrará una multa para la liberación de su mercancía que podrá ir de entre el 2% al 10% del valor de los productos retenidos.
                  <br /><br />
                  Como negocio interesado en el comercio a través de un proceso aduanal, es importante que prestes especial atención al cumplimiento de NOMs, no solo por tu reputación como negocio sino también para bienestar de tus clientes, para ello pide consulta a tu agencia aduanal quienes te guiarán en la resolución de este requisito.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading5">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                  ¿Qué advertencias son necesarias?
                </button>
              </div>
              <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordion">
                <div className="accordion-body">
                  En primer lugar, deberás de solicitar la fracción arancelaria del producto. Una vez que tengas la fracción arancelaria y corrobores que le solicitan el cumplimiento de la <b>NOM-055-SCFI-1994</b> deberás de contactar a una Unidad de Inspección para obtener una Constancia de Inspección.
                  <br /><br />
                  Debido a que el producto no puede etiquetarse en México, deberás de etiquetar en la aduana o regresar el producto con tu proveedor para que sea etiquetado.
                  <br />Te recomendamos analizar los costos de cada situación y evaluarlos.
                  <br /><br />
                  Una vez que nos contactes, te brindaremos la asesoría y apoyo para que puedas liberar tu producto de la aduana. El proceso será rápido, cada minuto cuenta, por lo que te dejamos nuestro correo (contacto@cecsatrade.mx) y número de contacto (56 5019 0728)
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading6">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                  ¿Por qué es importante cumplir con la NOM-055-SCFI-1994?
                </button>
              </div>
              <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordion">
                <div className="accordion-body">
                  La Norma Oficial Mexicana <b>NOM-055-SCFI-1994</b>  es de gran importancia ya que establece los requisitos para el etiquetado de materiales retardantes y/o inhibidores de flama y/o ignífugos en el ámbito comercial. Además esta normativa proporciona directrices claras y específicas para contribuir así a la seguridad y protección contra incendios en diversos entornos.
                  Cuando adquieras productos, ya sean nacionales o importados, es importante asegurarse de que contengan la información necesaria de manera clara y visible. Esto garantiza la transparencia en la comercialización y te permite tomar decisiones informadas sobre su uso y manejo.
                </div>
              </div>
            </li>
          </ul>
        </div>

        <img className='norm-info__ellipse' src="/img/ellipse-blue.svg" alt="ellipse" />
        <ServicesToOneClick />
        <ServiceSteps
          withNewsLetter={true}
        />
      </section>
    </>
  )
}

export default Nom055