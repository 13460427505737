import { Link, useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {
  Paragraph,
  Title,
  Button,
  ServiceSteps,
  JsonLd,
  ServicesToOneClick
} from '../../components';
import './styles.scss'
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

const Main = () => {
  const navigate = useNavigate();

  const [count1, setCount1] = useState<number>(0);
  const [count2, setCount2] = useState<number>(0);
  const [count3, setCount3] = useState<number>(0);
  const counterRef1 = useRef(null);
  const counterRef2 = useRef(null);
  const counterRef3 = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          let startTime: number | null = null;

          const animate = (currentTime: number) => {
            if (!startTime) startTime = currentTime;
            const progress = currentTime - startTime;
            const increment = Math.min(Math.floor(progress / 2000 * (50 - 0)), 50);

            setCount1(increment);

            if (increment < 50) {
              requestAnimationFrame(animate);
            }
          };

          const animate2 = (currentTime: number) => {
            if (!startTime) startTime = currentTime;
            const progress = currentTime - startTime;
            const increment = Math.min(Math.floor(progress / 1000 * (10 - 0)), 10);

            setCount2(increment);

            if (increment < 10) {
              requestAnimationFrame(animate2);
            }
          };

          const animate3 = (currentTime: number) => {
            if (!startTime) startTime = currentTime;
            const progress = currentTime - startTime;
            const increment = Math.min(Math.floor(progress / 3000 * (100 - 0)), 100);

            setCount3(increment);

            if (increment < 100) {
              requestAnimationFrame(animate3);
            }
          };

          requestAnimationFrame(animate);
          requestAnimationFrame(animate2);
          requestAnimationFrame(animate3);
          observer.unobserve(entry.target);
        }
      });
    }, { threshold: 0.5 });

    if (counterRef1.current) {
      observer.observe(counterRef1.current);
    }

    if (counterRef2.current) {
      observer.observe(counterRef2.current);
    }

    if (counterRef3.current) {
      observer.observe(counterRef3.current);
    }

    return () => {
      if (counterRef1.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(counterRef1.current);
      }
      if (counterRef2.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(counterRef2.current);
      }
      if (counterRef3.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(counterRef3.current);
      }
    };
  }, []);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'CECSA Trade';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          [
            {
              "@context": "https://schema.org",
              "@type": "Corporation",
              "@id": "https://www.cecsatrade.mx/#/schema/Organization/1",
              "url": "https://www.cecsatrade.mx/",
              "name": "CECSA Enterprise Solutions",
              "alternateName": "CECSA Trade",
              "description": "Unidad acreditada por la EMA y la Dirección General de Normas (DGN), para certificar el cumplimiento de Información Comercial a productos Nacionales o Importados conforme a lo establecido en las Normas Oficiales Mexicanas vigentes.",
              "image": [
                "https://www.cecsatrade.mx/img/favicon.svg"
              ],
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "AV CRUZ DEL SUR 3195 TERCER PISO INTERIOR 23",
                "addressLocality": "Zapopan",
                "addressRegion": "Jal",
                "postalCode": "45080",
                "addressCountry": "MX"
              },
              "telephone": "+52 348 105 1510",
              "email": "asesoria@cecsatrade.mx",
              "logo": "https://www.cecsatrade.mx/img/favicon.svg",
              "sameAs": [
                "https://www.facebook.com/CECSATRADE",
                "https://www.linkedin.com/company/cecsatrade/"
              ]
            },
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "@id": "https://www.cecsatrade.mx/#/schema/WebSite/1",
              "url": "https://www.cecsatrade.mx/",
              "name": "CECSA Enterprise Solutions",
              "alternateName": "CECSA Trade",
              "description": "Unidad acreditada por la EMA y la Dirección General de Normas (DGN), para certificar el cumplimiento de Información Comercial a productos Nacionales o Importados conforme a lo establecido en las Normas Oficiales Mexicanas vigentes.",
              "inLanguage": "es-MX"
            },
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "@id": "https://www.cecsatrade.mx/",
              "url": "https://www.cecsatrade.mx/",
              "name": "Unidad de Verificación",
              "description": "Unidad acreditada por la EMA y la Dirección General de Normas (DGN), para certificar el cumplimiento de Información Comercial a productos Nacionales o Importados conforme a lo establecido en las Normas Oficiales Mexicanas vigentes.",
              "isPartOf": {
                "@id": "https://www.cecsatrade.mx/#/schema/WebSite/1"
              },
              "datePublished": "2023-01-31T08:08:40-06:00",
              "dateModified": "2023-01-31T08:43:03-06:00",
              "primaryImageOfPage": {
                "@id": "https://www.cecsatrade.mx/img/favicon.svg"
              }
            }
          ]
        }
      />
      <Helmet>
        <meta
          name="description"
          content="CECSA Trade - Unidad de inspección para etiquetado"
        />
      </Helmet>
      <div className='main'>
        <div className='main__section-1'>
          <section className="hero-box">
            <div className="hero-box__background">
              <div className="hero-box__logo-container">
                <svg className="hero-box__logo" viewBox="0 0 730 440" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.443 261.29V114.181C10.443 96.2079 16.0891 81.1244 25.7233 70.5627C35.3398 60.0204 49.0753 53.8179 65.6244 53.8179C82.1787 53.8179 95.8637 59.9705 105.429 70.4805C115.013 81.011 120.617 96.0927 120.617 114.181V142.899H89.4948V114.181C89.4948 105.925 87.1911 99.0404 82.9728 94.1806C78.727 89.2891 72.7222 86.6642 65.8135 86.6642C58.9047 86.6642 52.8999 89.2891 48.6541 94.1806C44.4359 99.0404 42.1321 105.925 42.1321 114.181V261.076C42.1321 269.333 44.4359 276.217 48.6541 281.077C52.8999 285.969 58.9047 288.594 65.8135 288.594C72.7038 288.594 78.7088 286.036 82.9627 281.196C87.1904 276.385 89.4948 269.547 89.4948 261.29V225.944H120.617V261.29C120.617 279.374 114.969 294.456 105.36 304.989C95.7686 315.503 82.0813 321.654 65.6244 321.654C49.1664 321.654 35.4296 315.502 25.7903 304.985C16.1348 294.45 10.443 279.368 10.443 261.29Z" stroke="#7FC0C6" stroke-width="5" />
                  <path d="M199.143 284.958H264.347V317.804H164.953V57.6658H264.347V90.5122H199.143H196.643V93.0122V166.994V169.494H199.143H250.352V202.341H199.143H196.643V204.841V282.458V284.958H199.143Z" stroke="#004B63" stroke-width="5" />
                  <path d="M304.523 261.289V114.18C304.523 96.0972 310.171 81.0149 319.78 70.4818C329.371 59.9678 343.059 53.8171 359.516 53.8171C375.972 53.8171 389.66 59.9678 399.251 70.4818C408.86 81.0149 414.508 96.0972 414.508 114.18V142.898H383.386V114.18C383.386 105.924 381.082 99.0396 376.864 94.1798C372.618 89.2883 366.613 86.6634 359.705 86.6634C352.796 86.6634 346.791 89.2883 342.545 94.1798C338.327 99.0396 336.023 105.924 336.023 114.18V261.076C336.023 269.332 338.327 276.216 342.545 281.076C346.791 285.968 352.796 288.593 359.705 288.593C366.613 288.593 372.618 285.968 376.864 281.076C381.082 276.216 383.386 269.332 383.386 261.076V225.729H414.508V261.076C414.508 279.159 408.86 294.241 399.251 304.774C389.66 315.288 375.972 321.439 359.516 321.439C343.052 321.439 329.364 315.337 319.776 304.877C310.171 294.398 304.523 279.372 304.523 261.289Z" stroke="#7FC0C6" stroke-width="5" />
                  <path d="M499.372 201.822L499.367 201.817L499.362 201.812C481.161 183.152 469.635 168.869 462.642 155.718C455.695 142.653 453.171 130.61 453.171 116.319C453.171 97.0641 458.262 81.3858 467.359 70.5663C476.414 59.7981 489.634 53.6032 506.461 53.6032C523.295 53.6032 536.51 59.7487 545.561 70.5373C554.656 81.3793 559.751 97.1624 559.751 116.746V121.359L529.386 124.848V120.167C529.386 109.669 527.511 101.354 523.7 95.5892C519.792 89.6773 513.982 86.6634 506.839 86.6634C499.879 86.6634 494.094 89.1022 490.114 94.0679C486.202 98.9483 484.293 105.947 484.293 114.608C484.293 132.373 492.571 145.225 518.613 173.601C536.279 192.934 547.777 207.231 554.879 220.257C561.925 233.179 564.668 244.899 564.668 259.151C564.668 277.544 558.791 293.225 548.834 304.278C538.895 315.311 524.778 321.867 507.974 321.867C474.607 321.867 451.091 295.655 451.091 258.937V251.758L481.456 248.27V255.302C481.456 265.282 483.806 273.628 488.33 279.54C492.91 285.527 499.566 288.806 507.596 288.806C515.463 288.806 522.077 286.133 526.71 281.137C531.322 276.164 533.736 269.143 533.736 260.862C533.736 252.115 532.075 244.465 526.856 235.375C521.705 226.405 513.105 216.064 499.372 201.822Z" stroke="#7FC0C6" stroke-width="5" />
                  <path d="M678.199 261.375L677.901 259.217H675.723H636.197H634.012L633.719 261.382L626.069 318.018H594.473L633.996 57.8797H678.869L718.392 318.018H686.033L678.199 261.375ZM670.995 225.515H673.854L673.472 222.682L658.532 111.709H653.577L638.636 222.682L638.255 225.515H641.114H670.995Z" stroke="#7FC0C6" stroke-width="5" />
                  <path d="M272.56 367.19H262.713V359.071H292.809V367.19H282.962H281.462V368.69V433.902H274.06V368.69V367.19H272.56Z" stroke="#FBB500" stroke-width="3" />
                  <path d="M326.139 400.912L334.373 433.902H326.89L319.598 402.984L319.326 401.829H318.138H312.654H311.154V403.329V433.902H303.752V359.071H318.516C323.141 359.071 326.777 360.734 329.276 363.607C331.795 366.503 333.281 370.772 333.281 376.173V384.726C333.281 391.643 330.774 396.655 326.768 399.297L325.882 399.882L326.139 400.912ZM311.154 391.996V393.496H312.654H318.138C320.459 393.496 322.505 392.736 323.955 391.131C325.388 389.546 326.068 387.327 326.068 384.726V375.746C326.068 373.266 325.442 371.056 324.048 369.445C322.626 367.803 320.572 366.976 318.138 366.976H312.654H311.154V368.476V391.996Z" stroke="#FBB500" stroke-width="3" />
                  <path d="M367.094 417.887L366.923 416.582H365.607H354.449H353.133L352.962 417.887L350.863 433.902H343.513L354.422 359.071H365.823L376.733 433.902H369.193L367.094 417.887ZM364.283 408.25H365.988L365.771 406.559L361.61 374.059L358.635 374.059L354.474 406.559L354.258 408.25H355.962H364.283Z" stroke="#FBB500" stroke-width="3" />
                  <path d="M397.001 367.19H395.501V368.69V424.283V425.783H397.001H402.485C404.806 425.783 406.852 425.023 408.303 423.418C409.735 421.833 410.415 419.614 410.415 417.013V375.96C410.415 373.48 409.789 371.27 408.395 369.659C406.973 368.017 404.92 367.19 402.485 367.19H397.001ZM388.099 359.071H402.864C407.488 359.071 411.124 360.734 413.623 363.607C416.142 366.503 417.628 370.772 417.628 376.173V416.799C417.628 422.189 416.1 426.46 413.556 429.361C411.028 432.243 407.387 433.902 402.864 433.902H388.099V359.071Z" stroke="#FBB500" stroke-width="3" />
                  <path d="M440.309 425.783H457.91V433.902H431.219V359.071H457.91V367.19H440.309H438.809V368.69V390.286V391.786H440.309H454.128V399.904H440.309H438.809V401.404V424.283V425.783H440.309Z" stroke="#FBB500" stroke-width="3" />
                </svg>
              </div>
            </div>
          </section>
          <Title title='UNIDAD DE INSPECCIÓN PARA ETIQUETADO' />
          <br />
          <Paragraph text='Tramita tus folios para importación el mismo día. Obten tu certificado de etiquetado' />
          <br />
          <Button
            text={'!Regístrate al taller¡'}
            onClick={() => navigate('/')}
            type={'ligth'}
            size='big'
            anchor={true}
            // href="mailto:asesoria@cecsatrade.mx"
            href='https://form.jotform.com/242835094672867'
            target="_blank"
          />
          <img src="/img/taller.jpeg" alt="login-app" className='main__img__taller' rel="preload" data-aos="zoom-in-up" data-aos-duration="500" />
          {/* <img src="/img/login-app.svg" alt="login-app" className='main__img' rel="preload" /> */}
        </div>
        <section className='main__section-2' data-aos="zoom-in-up">
          <Title title='¿Qué nos hace diferentes?' />
          <div className='main__section-2__container'>
            <div id="element1" className='main__section-2__element'>
              <img src="/img/section1.svg" alt="trámites agiles" className='main__section-2__element__img' />
              <div className='main__section-2__element__text'>
                <span className='main__section-2__element__text__number' ref={counterRef1}>+{count1}%</span>
                <span>Trámites Ágiles</span>
              </div>
            </div>
            <div id="element2" className='main__section-2__element'>
              <img src="/img/section2.svg" alt="años de experiencia" className='main__section-2__element__img' />
              <div className='main__section-2__element__text'>
                <span className='main__section-2__element__text__number' ref={counterRef2}>+{count2}</span>
                <span>Años de experiencia</span>
              </div>
            </div>
            <div id="element3" className='main__section-2__element'>
              <img src="/img/section3.svg" alt="empresas atendidas" className='main__section-2__element__img' />
              <div className='main__section-2__element__text'>
                <span className='main__section-2__element__text__number' ref={counterRef3}>+{count3}</span>
                <span>Empresas atendidas</span>
              </div>
            </div>
          </div>
        </section>
        <ServicesToOneClick />
        <div className='main__image-container'>
          <img src="/img/elipse.svg" alt="circle-1" className='main__image-container__elipse-1' />
        </div>
        <section className='main__norms' id='norms'>
          <h1>
            Todas las normas a 1
            <br />
            click de distancia
          </h1>
          <p className='main__norms__description'>Diseñamos nuestros procesos para que puedas tener la información disponible desde tu computadora o teléfono.</p>
          <br />
          <Link to={'nom-003-ssa1-2006'} className='main__norms__item' data-aos="fade-right" data-aos-duration="500">
            <img src="/img/nom-003.svg" alt="nom-003" className='main__norms__item__logo' />
            <h2 className='main__norms__item__title'>PINTURAS</h2>
            <div className='main__norms__item__description'>
              <h3 className='main__norms__item__description__sub-title'>NOM-003-SSA1-2006</h3>
              <p className='main__norms__item__description__text'>Salud ambiental. Requisitos sanitartios que debe satisfacer el etiquetado de pinturas, tintas, barnices, lacas y esmaltes.</p>
            </div>
          </Link>
          <Link to={'nom-004-se-2021'} className='main__norms__item' data-aos="fade-right" data-aos-duration="500">
            <img src="/img/nom-004.svg" alt="nom-004" className='main__norms__item__logo' />
            <h2 className='main__norms__item__title'>TEXTILES</h2>
            <div className='main__norms__item__description'>
              <h3 className='main__norms__item__description__sub-title'>NOM-004-SE-2021</h3>
              <p className='main__norms__item__description__text'>Información comercial-etiquetado de productos textiles, prendas de vestir, sus accesorios y ropa de casa.</p>

            </div>
          </Link>
          <Link to={'nom-015-scfi-2007'} className='main__norms__item' data-aos="fade-right" data-aos-duration="500">
            <img src="/img/nom-015.svg" alt="nom-015" className='main__norms__item__logo' />
            <h2 className='main__norms__item__title'>JUGUETES</h2>
            <div className='main__norms__item__description'>
              <h3 className='main__norms__item__description__sub-title'>NOM-015-SCFI-2007</h3>
              <p className='main__norms__item__description__text'>Información comercial-etiquetado para juguetes.</p>

            </div>
          </Link>
          <Link to={'nom-020-scfi-1997'} className='main__norms__item' data-aos="fade-right" data-aos-duration="500">
            <img src="/img/nom-020.svg" alt="nom-020" className='main__norms__item__logo' />
            <h2 className='main__norms__item__title'>CUEROS</h2>
            <div className='main__norms__item__description'>
              <h3 className='main__norms__item__description__sub-title'>NOM-020-SCFI-1997</h3>
              <p className='main__norms__item__description__text'>Etiquetado de cueros y pieles curtidas naturales y materiales sintéticos o artificiales con esa aperiencia, calzado, marroquinería, así como los productos elaborados con dichos materiales.</p>

            </div>
          </Link>
          <Link to={'nom-024-scfi-2013'} className='main__norms__item' data-aos="fade-right" data-aos-duration="500">
            <img src="/img/nom-024.svg" alt="nom-024" className='main__norms__item__logo' />
            <h2 className='main__norms__item__title'>ELECTRÓNICOS</h2>
            <div className='main__norms__item__description'>
              <h3 className='main__norms__item__description__sub-title'>NOM-024-SCFI-2013</h3>
              <p className='main__norms__item__description__text'>Información comercial para empaques, instructivos y garantías de los productos electrónicos, eléctricos y electrodomesticos.</p>

            </div>
          </Link>
          <Link to={'nom-050-scfi-2004'} className='main__norms__item' data-aos="fade-right" data-aos-duration="500">
            <img src="/img/nom-050.svg" alt="nom-050" className='main__norms__item__logo' />
            <h2 className='main__norms__item__title'>PRODUCTOS EN GENERAL</h2>
            <div className='main__norms__item__description'>
              <h3 className='main__norms__item__description__sub-title'>NOM-050-SCFI-2004</h3>
              <p className='main__norms__item__description__text'>Información comercial-etiquetado general de productos.</p>

            </div>
          </Link>
          <Link to={'nom-051-scfi-ssa1-2010'} className='main__norms__item' data-aos="fade-right" data-aos-duration="500">
            <img src="/img/nom-051.svg" alt="nom-051" className='main__norms__item__logo' />
            <h2 className='main__norms__item__title'>ALIMENTOS</h2>
            <div className='main__norms__item__description'>
              <h3 className='main__norms__item__description__sub-title'>NOM-051-SCFI/SSA1-2010</h3>
              <p className='main__norms__item__description__text'>Especificaciones generales de etiquetado para alimentos y bebidas no alcohólicas preenvasados-información comercial sanitaria.</p>

            </div>
          </Link>
          <Link to={'nom-055-scfi-1994'} className='main__norms__item' data-aos="fade-right" data-aos-duration="500">
            <img src="/img/nom-055.svg" alt="nom-055" className='main__norms__item__logo' />
            <h2 className='main__norms__item__title'>EXTINTORES</h2>
            <div className='main__norms__item__description'>
              <h3 className='main__norms__item__description__sub-title'>NOM-055-SCFI-1994</h3>
              <p className='main__norms__item__description__text'>Información comercial - materiales retardantes y/o inhibidores de flama y/o ignifugos - Etiquetado.</p>

            </div>
          </Link>
          <Link to={'nom-139-scfi-2012'} className='main__norms__item' data-aos="fade-right" data-aos-duration="500">
            <img src="/img/nom-139.svg" alt="nom-139" className='main__norms__item__logo' />
            <h2 className='main__norms__item__title'>VAINILLA</h2>
            <div className='main__norms__item__description'>
              <h3 className='main__norms__item__description__sub-title'>NOM-139-SCFI-2012</h3>
              <p className='main__norms__item__description__text'>Información comercial-etiquetado de extracto natural de vainilla (Vainilla ssp), derivados y sititutos.</p>

            </div>
          </Link>
          <Link to={'nom-141-ssa1-scfi-2012'} className='main__norms__item' data-aos="fade-right" data-aos-duration="500">
            <img src="/img/nom-141.svg" alt="nom-141" className='main__norms__item__logo' />
            <h2 className='main__norms__item__title'>COSMÉTICOS</h2>
            <div className='main__norms__item__description'>
              <h3 className='main__norms__item__description__sub-title'>NOM-141-SSA1/SCFI-2012</h3>
              <p className='main__norms__item__description__text'>Etiquetado para productos cosméticos preenvasados. Etiquetado sanitario y comercial</p>

            </div>
          </Link>
          <Link to={'nom-142-scfi-ssa1-2014'} className='main__norms__item' data-aos="fade-right" data-aos-duration="500">
            <img src="/img/nom-142.svg" alt="nom-142" className='main__norms__item__logo' />
            <h2 className='main__norms__item__title'>BEBIDAS ALCOHOLICAS</h2>
            <div className='main__norms__item__description'>
              <h3 className='main__norms__item__description__sub-title'>NOM-142-SCFI/SSA1-2014</h3>
              <p className='main__norms__item__description__text'>Bebidas alcoholicas. especificaciones sanitarias. Etiquetado sanitario y comercial.</p>

            </div>
          </Link>
          <Link to={'nom-173-se-2021'} className='main__norms__item' data-aos="fade-right" data-aos-duration="500">
            <img src="/img/nom-173.svg" alt="nom-173" className='main__norms__item__logo' />
            <h2 className='main__norms__item__title'>JUGOS</h2>
            <div className='main__norms__item__description'>
              <h3 className='main__norms__item__description__sub-title'>NOM-173-SE-2021</h3>
              <p className='main__norms__item__description__text'>Jugos, agua de coco, néctares, bebidas no alcohólicas con contenido de vegetal o fruta u hortaliza y bebidas saborizadas no alcohólicas preenvasadas - denominaciones - especificaciones - información comercial y métodos de prueba.</p>

            </div>
          </Link>
          <Link to={'nom-187-ssa1-scfi-2002'} className='main__norms__item' data-aos="fade-right" data-aos-duration="500">
            <img src="/img/nom-187.svg" alt="nom-187" className='main__norms__item__logo' />
            <h2 className='main__norms__item__title'>MASA Y TORTILLAS</h2>
            <div className='main__norms__item__description'>
              <h3 className='main__norms__item__description__sub-title'>NOM-187-SSA1/SCFI-2002</h3>
              <p className='main__norms__item__description__text'>Productos y servicios. masa, tortillas, tostadas y harinas preparadas para su elaboración y establecimientos donde se procesan. Especificaciones sanitarias. Información comercial. Métodos de pruebas.</p>

            </div>
          </Link>
          <Link to={'nom-189-ssa1-scfi-2018'} className='main__norms__item' data-aos="fade-right" data-aos-duration="500">
            <img src="/img/nom-189.svg" alt="nom-189" className='main__norms__item__logo' />
            <h2 className='main__norms__item__title'>PRODUCTOS DE ASEO</h2>
            <div className='main__norms__item__description'>
              <h3 className='main__norms__item__description__sub-title'>NOM-189-SSA1/SCFI-2018</h3>
              <p className='main__norms__item__description__text'>Productos y servicios. Etiquetado y envasado para productos de aseo de uso doméstico.</p>

            </div>
          </Link>
          <Link to={'nom-235-se-2020'} className='main__norms__item' data-aos="fade-right" data-aos-duration="500">
            <img src="/img/nom-235.svg" alt="nom-235" className='main__norms__item__logo' />
            <h2 className='main__norms__item__title'>ATÚN Y BONITA</h2>
            <div className='main__norms__item__description'>
              <h3 className='main__norms__item__description__sub-title'>NOM-235-SE-2020</h3>
              <p className='main__norms__item__description__text'>Atún y bonita preenvasados - denominación - especificaciones - información comercial y métodos de prueba.</p>

            </div>
          </Link>
        </section>
        <div className='main__image-container'>
          <img src="/img/elipse.svg" alt="circle-2" className='main__image-container__elipse-2' />
        </div>
        <ServiceSteps withNewsLetter={true} />
      </div>
    </>
  )
}

export default Main;