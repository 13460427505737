import axios from 'axios';

import { Link, Outlet, useLocation } from 'react-router-dom';
import './styles.scss'
import { Button, Footer, Spinner } from '../../components';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';

interface Form {
  name: string
  email: string
  number: string
  service: string
  norms: string[]
  modelsQuantity: string
  comments: string
}

export const EmptyLayout = () => {
  const {
    setModalOpen,
    modalOpen
  } = useContext(AppContext);

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [mobileItemOpen, setMobileItemOpen] = useState<number>(0);
  const [norm1, setNorm1] = useState<boolean>(false);
  const [norm2, setNorm2] = useState<boolean>(false);
  const [norm3, setNorm3] = useState<boolean>(false);
  const [norm4, setNorm4] = useState<boolean>(false);
  const [norm5, setNorm5] = useState<boolean>(false);
  const [norm6, setNorm6] = useState<boolean>(false);
  const [norm7, setNorm7] = useState<boolean>(false);
  const [norm8, setNorm8] = useState<boolean>(false);
  const [norm9, setNorm9] = useState<boolean>(false);
  const [norm10, setNorm10] = useState<boolean>(false);
  const [norm11, setNorm11] = useState<boolean>(false);
  const [norm12, setNorm12] = useState<boolean>(false);
  const [norm13, setNorm13] = useState<boolean>(false);
  const [norm14, setNorm14] = useState<boolean>(false);
  const [norm15, setNorm15] = useState<boolean>(false);
  const [formData, setFormData] = useState<Form>({
    name: '',
    email: '',
    number: '',
    service: '',
    norms: [],
    modelsQuantity: '',
    comments: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [successImage, setSuccessImage] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const { pathname } = useLocation();

  const validForm = (): boolean => {
    setSuccessImage(false);
    if (formData.name === '') {
      setErrorMessage('Ingresa tu nombre');
      return false;
    }
    if (formData.email === '') {
      setErrorMessage('Ingresa tu e-mail');
      return false;
    }
    if (formData.number === '') {
      setErrorMessage('Ingresa tu número de contacto');
      return false;
    }
    if (formData.service === '') {
      setErrorMessage('Selecciona un servicio a solicitar');
      return false;
    }
    if (formData.modelsQuantity === '') {
      setErrorMessage('Especifica en número de modelos');
      return false;
    }

    if (
      norm1 === false
      && norm2 === false
      && norm3 === false
      && norm4 === false
      && norm5 === false
      && norm6 === false
      && norm7 === false
      && norm8 === false
      && norm9 === false
      && norm10 === false
      && norm11 === false
      && norm12 === false
      && norm13 === false
      && norm14 === false
      && norm15 === false) {
      setErrorMessage('Se debe seleccionar mínimo una norma');
      return false;
    }

    return true;
  };

  const sendEmail = async () => {
    if (validForm()) {
      setErrorMessage('');

      setIsLoading(true);

      if (norm1) {
        formData.norms.push('NOM-003-SSA1-2006');
      }

      if (norm2) {
        formData.norms.push('NOM-004-SE-2021');
      }

      if (norm3) {
        formData.norms.push('NOM-015-SCFI-2007');
      }

      if (norm4) {
        formData.norms.push('NOM-020-SCFI-1997');
      }

      if (norm5) {
        formData.norms.push('NOM-024-SCFI-2013');
      }

      if (norm6) {
        formData.norms.push('NOM-050-SCFI-2004');
      }

      if (norm7) {
        formData.norms.push('NOM-051-SCFI/SSA1-2010');
      }

      if (norm8) {
        formData.norms.push('NOM-055-SCFI-1994');
      }

      if (norm9) {
        formData.norms.push('NOM-139-SCFI-2012');
      }

      if (norm10) {
        formData.norms.push('NOM-141-SSA1/SCFI-2012');
      }

      if (norm11) {
        formData.norms.push('NOM-142-SCFI/SSA1-2014');
      }

      if (norm12) {
        formData.norms.push('NOM-173-SE-2021');
      }

      if (norm13) {
        formData.norms.push('NOM-187-SSA1/SCFI-2002');
      }

      if (norm14) {
        formData.norms.push('NOM-189-SSA1/SCFI-2018');
      }

      if (norm15) {
        formData.norms.push('NOM-235-SE-2020');
      }

      try {
        await axios.post(
          'https://api.cecsatrade.mx/api/v2/sessions/send-landing-email',
          {
            ...formData,
            norms: formData.norms.map((norm) => `${norm} `)
          }
        );

        setFormData({
          name: '',
          email: '',
          number: '',
          service: '',
          norms: [],
          modelsQuantity: '',
          comments: ''
        });
        setNorm1(false);
        setNorm2(false);
        setNorm3(false);
        setNorm4(false);
        setNorm5(false);
        setNorm6(false);
        setNorm7(false);
        setNorm8(false);
        setNorm9(false);
        setNorm10(false);
        setNorm11(false);
        setNorm12(false);
        setNorm13(false);
        setNorm14(false);
        setNorm15(false);
        setSuccessImage(true);
        setIsLoading(false);
      } catch (error) {
        setErrorMessage('Hubo un error al enviar el mensaje, intentalo de nuevo');
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const closeModal = () => {
    setErrorMessage('');
    setSuccessImage(false);
    setModalOpen(false);
    setFormData({
      name: '',
      email: '',
      number: '',
      service: '',
      norms: [],
      modelsQuantity: '',
      comments: ''
    });
    setNorm1(false);
    setNorm2(false);
    setNorm3(false);
    setNorm4(false);
    setNorm5(false);
    setNorm6(false);
    setNorm7(false);
    setNorm8(false);
    setNorm9(false);
    setNorm10(false);
    setNorm11(false);
    setNorm12(false);
    setNorm13(false);
    setNorm14(false);
    setNorm15(false);
  };

  return (
    <>
      <div className={`modal-element modal-element${modalOpen ? '--open' : ''}`}>
        <div className='modal-element__background' onClick={() => closeModal()}></div>
        <div className='modal-element__content'>
          {
            successImage ? (
              <div className='modal-element__content__image-success-container'>
                <div className='modal-element__content__image-success-container__data'>
                  <p className='modal-element__content__image-success-container__title-1'>Recibido</p>
                  <p className='modal-element__content__image-success-container__title-2'>Nos pondremos en contacto contigo a la brevedad</p>
                  <p className='modal-element__content__image-success-container__title-3'>Mantente al tanto en nuestras redes sociales</p>
                  <div className='modal-element__content__image-success-container__social-media'>
                    <a href='https://www.facebook.com/CECSATRADE/?locale=es_LA' target='_blank' rel="noreferrer">
                      <img src="/img/facebook.svg" alt="facebook" />
                    </a>
                    <a href='https://www.tiktok.com/@cecsatrade' target='_blank' rel="noreferrer">
                      <img src="/img/tiktok.svg" alt="tiktok" />
                    </a>
                    <a href='/' target='_blank' rel="noreferrer">
                      <img src="/img/x.svg" alt="x" />
                    </a>
                    <a href='https://www.instagram.com/cecsa_trade_/?hl=es' target='_blank' rel="noreferrer">
                      <img src="/img/instagram.svg" alt="instagram" />
                    </a>
                    <a href='https://www.youtube.com/channel/UCtW7_XCAK-uE9hJPVRXsTJw' target='_blank' rel="noreferrer">
                      <img src="/img/youtube.svg" alt="youtube" />
                    </a>
                    <a href='https://mx.linkedin.com/company/cecsatrade' target='_blank' rel="noreferrer" >
                      <img src="/img/linkedin.svg" alt="linkedin" />
                    </a>
                    <a href="https://wa.me/message/YZR3TYWZRARDH1" target='_blank' rel="noreferrer">
                      <img src="/img/whatsapp.svg" alt="whatsapp" />
                    </a>
                  </div>
                </div>
                <img src="/img/success-form-2.svg" alt="form-background" className='modal-element__content__image-success-container__image' />
              </div>
            ) : (
              <>
                <img src="/img/man-right.svg" alt="form-background" className='modal-element__content__image' />
                <img src="/img/plant.svg" alt="plant-background" className='modal-element__content__image-plant' />
                <div className='modal-element__content__header'>
                  <div className='modal-element__content__header__top'></div>
                  <span>Tus datos</span>
                </div>
                {
                  errorMessage && (
                    <b style={{ color: 'red', fontSize: '1.5rem' }}>{errorMessage}</b>
                  )
                }
                <div className='modal-element__content__form'>
                  <div className='modal-element__content__form__inputs'>
                    <div className='modal-element__content__form__inputs__item'>
                      <label htmlFor="name">Nombre <b style={{ color: 'red' }}>*</b></label>
                      <input type="text" id='name' value={formData.name} onChange={(e: any) => {
                        setFormData({
                          ...formData,
                          name: e.target.value
                        });
                      }} />
                    </div>
                    <div className='modal-element__content__form__inputs__item'>
                      <label htmlFor="email">Correo <b style={{ color: 'red' }}>*</b></label>
                      <input type="email" id='email' value={formData.email} onChange={(e: any) => {
                        setFormData({
                          ...formData,
                          email: e.target.value
                        });
                      }} />
                    </div>
                    <div className='modal-element__content__form__inputs__item'>
                      <label htmlFor="number">Número de contacto <b style={{ color: 'red' }}>*</b></label>
                      <input type="number" id='number' value={formData.number} onChange={(e: any) => {
                        setFormData({
                          ...formData,
                          number: e.target.value
                        });
                      }} />
                    </div>
                    <div className='modal-element__content__form__inputs__item'>
                      <label htmlFor="service">Servicio solicitado <b style={{ color: 'red' }}>*</b></label>
                      <select name="service" id="service" value={formData.service} onChange={(e: any) => {
                        setFormData({
                          ...formData,
                          service: e.target.value
                        });
                      }}>
                        <option value="none" selected>Selecciona...</option>
                        <option value="dictamen">SERVICIO DE DICTAMEN</option>
                        <option value="constancia">SERVICIO DE CONSTANCIA</option>
                        <option value="revision">SERVICIO DE REVISION</option>
                      </select>
                    </div>
                    <div className='modal-element__content__form__inputs__item'>
                      <label htmlFor="models">Número de modelo(s) <b style={{ color: 'red' }}>*</b></label>
                      <input type="number" id='models' value={formData.modelsQuantity} onChange={(e: any) => {
                        setFormData({
                          ...formData,
                          modelsQuantity: e.target.value
                        });
                      }} />
                    </div>
                    <div className='modal-element__content__form__inputs__item'>
                      <label htmlFor="comments" id='label-comments'>Comentarios</label>
                      <textarea name="comments" id="comments" value={formData.comments} onChange={(e: any) => {
                        setFormData({
                          ...formData,
                          comments: e.target.value
                        });
                      }}></textarea>
                    </div>
                  </div>
                  <div className='modal-element__content__form__textarea'>
                    <label className='modal-element__content__form__textarea__title'>Norma(s) <b style={{ color: 'red' }}>*</b></label>
                    <div className='modal-element__content__form__inputs__radio'>
                      <input type="radio" id="003" name="norm1" value="NOM-003-SSA1-2006" onClick={() => setNorm1(!norm1)} checked={norm1}></input>
                      <label htmlFor="003">NOM-003-SSA1-2006 - PINTURAS</label>
                    </div>
                    <div className='modal-element__content__form__inputs__radio'>
                      <input type="radio" id="004" name="norm2" value="NOM-004-SE-2021" onClick={() => setNorm2(!norm2)} checked={norm2} />
                      <label htmlFor="004">NOM-004-SE-2021 - TEXTILES</label>
                    </div>
                    <div className='modal-element__content__form__inputs__radio'>
                      <input type="radio" id="015" name="norm3" value="NOM-015-SCFI-2007" onClick={() => setNorm3(!norm3)} checked={norm3} />
                      <label htmlFor="015">NOM-015-SCFI-2007 - JUGUETES</label>
                    </div>
                    <div className='modal-element__content__form__inputs__radio'>
                      <input type="radio" id="020" name="norm4" value="NOM-020-SCFI-1997" onClick={() => setNorm4(!norm4)} checked={norm4} />
                      <label htmlFor="020">NOM-020-SCFI-1997 - CUEROS</label>
                    </div>
                    <div className='modal-element__content__form__inputs__radio'>
                      <input type="radio" id="024" name="norm5" value="NOM-024-SCFI-2013" onClick={() => setNorm5(!norm5)} checked={norm5} />
                      <label htmlFor="024">NOM-024-SCFI-2013 - ELECTRÓNICOS</label>
                    </div>
                    <div className='modal-element__content__form__inputs__radio'>
                      <input type="radio" id="050" name="norm6" value="NOM-050-SCFI-2004" onClick={() => setNorm6(!norm6)} checked={norm6} />
                      <label htmlFor="050">NOM-050-SCFI-2004 - PRODUCTOS EN GENERAL</label>
                    </div>
                    <div className='modal-element__content__form__inputs__radio'>
                      <input type="radio" id="051" name="norm7" value="NOM-051-SCFI/SSA1-2010" onClick={() => setNorm7(!norm7)} checked={norm7} />
                      <label htmlFor="051">NOM-051-SCFI/SSA1-2010 - ALIMENTOS</label>
                    </div>
                    <div className='modal-element__content__form__inputs__radio'>
                      <input type="radio" id="055" name="norm8" value="NOM-055-SCFI-1994" onClick={() => setNorm8(!norm8)} checked={norm8} />
                      <label htmlFor="055">NOM-055-SCFI-1994 - EXTINTORES</label>
                    </div>
                    <div className='modal-element__content__form__inputs__radio'>
                      <input type="radio" id="139" name="norm9" value="NOM-139-SCFI-2012" onClick={() => setNorm9(!norm9)} checked={norm9} />
                      <label htmlFor="139">NOM-139-SCFI-2012 - VAINILLA</label>
                    </div>
                    <div className='modal-element__content__form__inputs__radio'>
                      <input type="radio" id="141" name="norm10" value="NOM-141-SSA1/SCFI-2012" onClick={() => setNorm10(!norm10)} checked={norm10} />
                      <label htmlFor="141">NOM-141-SSA1/SCFI-2012 - COSMÉTICOS</label>
                    </div>
                    <div className='modal-element__content__form__inputs__radio'>
                      <input type="radio" id="142" name="norm11" value="NOM-142-SCFI/SSA1-2014" onClick={() => setNorm11(!norm11)} checked={norm11} />
                      <label htmlFor="142">NOM-142-SCFI/SSA1-2014 - BEBIDAS ALCOHOLICAS</label>
                    </div>
                    <div className='modal-element__content__form__inputs__radio'>
                      <input type="radio" id="173" name="norm12" value="NOM-173-SE-2021" onClick={() => setNorm12(!norm12)} checked={norm12} />
                      <label htmlFor="173">NOM-173-SE-2021 - JUGOS</label>
                    </div>
                    <div className='modal-element__content__form__inputs__radio'>
                      <input type="radio" id="187" name="norm13" value="NOM-187-SSA1/SCFI-2002" onClick={() => setNorm13(!norm13)} checked={norm13} />
                      <label htmlFor="187">NOM-187-SSA1/SCFI-2002 - MASA Y TORTILLAS</label>
                    </div>
                    <div className='modal-element__content__form__inputs__radio'>
                      <input type="radio" id="189" name="norm14" value="NOM-189-SSA1/SCFI-2018" onClick={() => setNorm14(!norm14)} checked={norm14} />
                      <label htmlFor="189">NOM-189-SSA1/SCFI-2018 - PRODUCTOS DE ASEO</label>
                    </div>
                    <div className='modal-element__content__form__inputs__radio'>
                      <input type="radio" id="235" name="norm15" value="NOM-235-SE-2020" onClick={() => setNorm15(!norm15)} checked={norm15} />
                      <label htmlFor="235">NOM-235-SE-2020 - ATÚN Y BONITA</label>
                    </div>
                  </div>
                </div>
                <div className='modal-element__content__buttons-container'>
                  {
                    isLoading ? (
                      <Spinner />
                    ) : (
                      <>
                        <button className='modal-element__content__buttons-container__accept' onClick={() => sendEmail()}>Aceptar</button>
                        <button className='modal-element__content__buttons-container__cancel' onClick={() => closeModal()}>Cancelar</button>
                      </>
                    )
                  }
                </div>
              </>
            )
          }
        </div >
      </div >
      <nav className={`navigation-mobile navigation-mobile${menuOpen ? '--open' : ''}`}>
        <div className='navigation-mobile__ul-container'>
          <ul className='navigation-mobile__ul'>
            {/* <li className='navigation-mobile__ul__li' onClick={() => setMobileItemOpen(1)}>
              Plataforma
              <img src="/img/arrow-down.svg" alt="arrow" />
              <div className={`navigation-mobile__ul__li__dropdown-mobile navigation-mobile__ul__li__dropdown-mobile${mobileItemOpen === 1 ? '--open' : ''}`}>
                <div onClick={() => { setModalOpen(true); setMenuOpen(false); }} className='navigation-mobile__ul__li__dropdown-mobile__item'>
                  <span className='navigation-mobile__ul__li__dropdown-mobile__item__title'>SOLICITA UNA COTIZACIÓN</span>
                  <p className='navigation-mobile__ul__li__dropdown-mobile__item__text'>Comunicate con alguien del equipo de ventas para que te pueda proporcionar una cotización de acuerdo a tus necesidades.</p>
                </div>
                <a href="https://app.cecsatrade.mx/" onClick={() => setMenuOpen(false)} target='_blank' rel="noreferrer" className='navigation-mobile__ul__li__dropdown-mobile__item'>
                  <span className='navigation-mobile__ul__li__dropdown-mobile__item__title'>INGRESA A PLATAFORMA</span>
                  <p className='navigation-mobile__ul__li__dropdown-mobile__item__text'>Este servicio es para los clientes que ya registrados y que ya cuentan con contrato de servicios. Ingresa aquí para que puedas solicitar nuestros servicios.</p>
                </a>
              </div>
            </li> */}
            <li className='navigation-mobile__ul__li' onClick={() => setMobileItemOpen(2)}>
              Normas
              <img src="/img/arrow-down.svg" alt="arrow" />
              <div className={`navigation-mobile__ul__li__dropdown-mobile navigation-mobile__ul__li__dropdown-mobile${mobileItemOpen === 2 ? '--open' : ''}`}>
                <Link to={'nom-003-ssa1-2006'} onClick={() => setMenuOpen(false)} className='navigation-mobile__ul__li__dropdown-mobile__item'>
                  <span className='navigation-mobile__ul__li__dropdown-mobile__item__title'>NOM-003-SSA1-2006 - PINTURAS</span>
                </Link>
                <Link to={'nom-004-se-2021'} onClick={() => setMenuOpen(false)} className='navigation-mobile__ul__li__dropdown-mobile__item'>
                  <span className='navigation-mobile__ul__li__dropdown-mobile__item__title'>NOM-004-SE-2021 - TEXTILES</span>
                </Link>
                <Link to={'nom-015-scfi-2007'} onClick={() => setMenuOpen(false)} className='navigation-mobile__ul__li__dropdown-mobile__item'>
                  <span className='navigation-mobile__ul__li__dropdown-mobile__item__title'>NOM-015-SCFI-2007 - JUGUETES</span>
                </Link>
                <Link to={'nom-020-scfi-1997'} onClick={() => setMenuOpen(false)} className='navigation-mobile__ul__li__dropdown-mobile__item'>
                  <span className='navigation-mobile__ul__li__dropdown-mobile__item__title'>NOM-020-SCFI-1997 - PRODUCTOS DE CUERO</span>
                </Link>
                <Link to={'nom-024-scfi-2013'} onClick={() => setMenuOpen(false)} className='navigation-mobile__ul__li__dropdown-mobile__item'>
                  <span className='navigation-mobile__ul__li__dropdown-mobile__item__title'>NOM-024-SCFI-2013 - ELECTRÓNICOS</span>
                </Link>
                <Link to={'nom-139-scfi-2012'} onClick={() => setMenuOpen(false)} className='navigation-mobile__ul__li__dropdown-mobile__item'>
                  <span className='navigation-mobile__ul__li__dropdown-mobile__item__title'>NOM-139-SCFI-2012 - VAINILLA</span>
                </Link>
                <Link to={'nom-050-scfi-2004'} onClick={() => setMenuOpen(false)} className='navigation-mobile__ul__li__dropdown-mobile__item'>
                  <span className='navigation-mobile__ul__li__dropdown-mobile__item__title'>NOM-050-SCFI-2004 - ETIQUETADO GENERAL</span>
                </Link>
                <Link to={'nom-051-scfi-ssa1-2010'} onClick={() => setMenuOpen(false)} className='navigation-mobile__ul__li__dropdown-mobile__item'>
                  <span className='navigation-mobile__ul__li__dropdown-mobile__item__title'>NOM-051-SCFI/SSA1-2010 - ALIMENTOS</span>
                </Link>
                <Link to={'nom-055-scfi-1994'} onClick={() => setMenuOpen(false)} className='navigation-mobile__ul__li__dropdown-mobile__item'>
                  <span className='navigation-mobile__ul__li__dropdown-mobile__item__title'>NOM-055-SCFI-1994 - AEXTINTYORES Y MATERIALES IGNÍFUGOS</span>
                </Link>
                <Link to={'nom-141-ssa1-scfi-2012'} onClick={() => setMenuOpen(false)} className='navigation-mobile__ul__li__dropdown-mobile__item'>
                  <span className='navigation-mobile__ul__li__dropdown-mobile__item__title'>NOM-141-SSA1/SCFI-2012 - COSMÉTICOS</span>
                </Link>
                <Link to={'nom-142-scfi-ssa1-2014'} onClick={() => setMenuOpen(false)} className='navigation-mobile__ul__li__dropdown-mobile__item'>
                  <span className='navigation-mobile__ul__li__dropdown-mobile__item__title'>NOM-142-SCFI/SSA1-2014 - BEBIDAS ALCOHOLICAS</span>
                </Link>
                <Link to={'nom-173-se-2021'} onClick={() => setMenuOpen(false)} className='navigation-mobile__ul__li__dropdown-mobile__item'>
                  <span className='navigation-mobile__ul__li__dropdown-mobile__item__title'>NOM-173-SE-2021 - JUGOS</span>
                </Link>
                <Link to={'nom-187-ssa1-scfi-2002'} onClick={() => setMenuOpen(false)} className='navigation-mobile__ul__li__dropdown-mobile__item'>
                  <span className='navigation-mobile__ul__li__dropdown-mobile__item__title'>NOM-187-SSA1/SCFI-2002 - TORTILLAS Y MASA</span>
                </Link>
                <Link to={'nom-189-ssa1-scfi-2018'} onClick={() => setMenuOpen(false)} className='navigation-mobile__ul__li__dropdown-mobile__item'>
                  <span className='navigation-mobile__ul__li__dropdown-mobile__item__title'>NOM-189-SSA1/SCFI-2018 - ASEO Y LIMPIEZA</span>
                </Link>
                <Link to={'nom-235-se-2020'} onClick={() => setMenuOpen(false)} className='navigation-mobile__ul__li__dropdown-mobile__item'>
                  <span className='navigation-mobile__ul__li__dropdown-mobile__item__title'>NOM-235-SE-2020 - ATÚN Y BONITA</span>
                </Link>
              </div>
            </li>
            <li className='navigation-mobile__ul__li' onClick={() => setMobileItemOpen(3)}>
              Servicios
              <img src="/img/arrow-down.svg" alt="arrow" />

              <div className={`navigation-mobile__ul__li__dropdown-mobile navigation-mobile__ul__li__dropdown-mobile${mobileItemOpen === 3 ? '--open' : ''}`}>
                <Link to={'constancy'} onClick={() => setMenuOpen(false)} className='navigation-mobile__ul__li__dropdown-mobile__item'>
                  <span className='navigation-mobile__ul__li__dropdown-mobile__item__title'>CONSTANCIA DE INSPECCIÓN</span>
                </Link>
                <Link to={'tax-warehouse'} onClick={() => setMenuOpen(false)} className='navigation-mobile__ul__li__dropdown-mobile__item'>
                  <span className='navigation-mobile__ul__li__dropdown-mobile__item__title'>ETIQUETADO EN MÉXICO - ALMACEN FISCAL</span>
                </Link>
                <Link to={'private-warehouse'} onClick={() => setMenuOpen(false)} className='navigation-mobile__ul__li__dropdown-mobile__item'>
                  <span className='navigation-mobile__ul__li__dropdown-mobile__item__title'>ETIQUETADO EN MÉXICO - ALMACEN PARTICULAR</span>
                </Link>
                <Link to={'revision-service'} onClick={() => setMenuOpen(false)} className='navigation-mobile__ul__li__dropdown-mobile__item'>
                  <span className='navigation-mobile__ul__li__dropdown-mobile__item__title'>REVISIÓN DE ETIQUETADO</span>
                </Link>
              </div>
            </li>
            <li className='navigation-mobile__ul__li'>
              <a href="https://blog.cecsatrade.mx/" onClick={() => setMenuOpen(false)} target='_blank' rel="noreferrer">Blog</a>
            </li>
          </ul>
        </div>
      </nav>
      <nav className='navigation navigation' id='menu'>
        <div className='navigation__ul-container'>
          <ul className='navigation__ul'>
            <li className='navigation__ul__li'>
              <Link to={'/'}  >
                Inicio
              </Link>
            </li>
            {/* <li className='navigation__ul__li' onClick={() => setMobileItemOpen(1)}>
              Plataforma
              <img src="/img/arrow-down.svg" alt="arrow" />
              <ul className='navigation__ul__li__dropdown navigation__ul__li__dropdown--platform'>
                <li className='navigation__ul__li__dropdown__item'>
                  <div onClick={() => setModalOpen(true)} className='navigation__ul__li__dropdown__item__container__cta' >
                    <div className='navigation__ul__li__dropdown__item__container'>
                      <span className='navigation__ul__li__dropdown__item__container__title'>SOLICITA UNA COTIZACIÓN</span>
                      <div className='navigation__ul__li__dropdown__item__container__separator'></div>
                      <p className='navigation__ul__li__dropdown__item__container__text'>
                        Comunicate con alguien del equipo de ventas para que te pueda proporcionar una cotización de acuerdo a tus necesidades.
                      </p>
                    </div>
                  </div>
                </li>
                <li className='navigation__ul__li__dropdown__item'>
                  <a className='navigation__ul__li__dropdown__item__container__cta' href="https://app.cecsatrade.mx/" target='_blank' rel="noreferrer">
                    <div className='navigation__ul__li__dropdown__item__container'>
                      <span className='navigation__ul__li__dropdown__item__container__title'>INGRESA A PLATAFORMA</span>
                      <div className='navigation__ul__li__dropdown__item__container__separator'></div>
                      <p className='navigation__ul__li__dropdown__item__container__text'>
                        Este servicio es para los clientes que ya registrados y que ya cuentan con contrato de servicios. Ingresa aquí para que puedas solicitar nuestros servicios.
                      </p>
                    </div>
                  </a>
                </li>
              </ul>
            </li> */}
            <li className='navigation__ul__li' onClick={() => setMobileItemOpen(2)}>
              Normas
              <img src="/img/arrow-down.svg" alt="arrow" />
              <ul className='navigation__ul__li__dropdown navigation__ul__li__dropdown--norms'>
                <li className='navigation__ul__li__dropdown__item'>
                  <div className='navigation__ul__li__dropdown__item__container'>
                    <Link to={'nom-003-ssa1-2006'} className='navigation__ul__li__dropdown__item__container__title' >
                      NOM-003-SSA1-2006 - PINTURAS
                    </Link>
                  </div>
                  <div className='navigation__ul__li__dropdown__item__container'>
                    <Link to={'nom-004-se-2021'} className='navigation__ul__li__dropdown__item__container__title' >
                      NOM-004-SE-2021 - TEXTILES
                    </Link>
                  </div>
                  <div className='navigation__ul__li__dropdown__item__container'>
                    <Link to={'nom-015-scfi-2007'} className='navigation__ul__li__dropdown__item__container__title' >
                      NOM-015-SCFI-2007 - JUGUETES
                    </Link>
                  </div>
                  <div className='navigation__ul__li__dropdown__item__container'>
                    <Link to={'nom-020-scfi-1997'} className='navigation__ul__li__dropdown__item__container__title' >
                      NOM-020-SCFI-1997 - PRODUCTOS DE CUERO
                    </Link>
                  </div>
                  <div className='navigation__ul__li__dropdown__item__container'>
                    <Link to={'nom-024-scfi-2013'} className='navigation__ul__li__dropdown__item__container__title' >
                      NOM-024-SCFI-2013 - ELECTRÓNICOS
                    </Link>
                  </div>
                  <div className='navigation__ul__li__dropdown__item__container'>
                    <Link to={'nom-139-scfi-2012'} className='navigation__ul__li__dropdown__item__container__title' >
                      NOM-139-SCFI-2012 - VAINILLA
                    </Link>
                  </div>
                  <div className='navigation__ul__li__dropdown__item__container'>
                    <Link to={'nom-050-scfi-2004'} className='navigation__ul__li__dropdown__item__container__title' >
                      NOM-050-SCFI-2004 - ETIQUETADO GENERAL
                    </Link>
                  </div>
                  <div className='navigation__ul__li__dropdown__item__container'>
                    <Link to={'nom-051-scfi-ssa1-2010'} className='navigation__ul__li__dropdown__item__container__title' >
                      NOM-051-SCFI/SSA1-2010 - ALIMENTOS
                    </Link>
                  </div>
                  <div className='navigation__ul__li__dropdown__item__container'>
                    <Link to={'nom-055-scfi-1994'} className='navigation__ul__li__dropdown__item__container__title' >
                      NOM-055-SCFI-1994 EXTINTORES Y MATERIALES IGNÍFUGOS
                    </Link>
                  </div>
                  <div className='navigation__ul__li__dropdown__item__container'>
                    <Link to={'nom-141-ssa1-scfi-2012'} className='navigation__ul__li__dropdown__item__container__title' >
                      NOM-141-SSA1/SCFI-2012 - COSMÉTICOS
                    </Link>
                  </div>
                  <div className='navigation__ul__li__dropdown__item__container'>
                    <Link to={'nom-142-scfi-ssa1-2014'} className='navigation__ul__li__dropdown__item__container__title' >
                      NOM-142-SCFI/SSA1-2014 - BEBIDAS ALCOHOLICAS
                    </Link>
                  </div>
                  <div className='navigation__ul__li__dropdown__item__container'>
                    <Link to={'nom-173-se-2021'} className='navigation__ul__li__dropdown__item__container__title' >
                      NOM-173-SE-2021 - JUGOS
                    </Link>
                  </div>
                  <div className='navigation__ul__li__dropdown__item__container'>
                    <Link to={'nom-187-ssa1-scfi-2002'} className='navigation__ul__li__dropdown__item__container__title' >
                      NOM-187-SSA1/SCFI-2002 - TORTILLAS Y MASA
                    </Link>
                  </div>
                  <div className='navigation__ul__li__dropdown__item__container'>
                    <Link to={'nom-189-ssa1-scfi-2018'} className='navigation__ul__li__dropdown__item__container__title' >
                      NOM-189-SSA1/SCFI-2018 - ASEO Y LIMPIEZA
                    </Link>
                  </div>
                  <div className='navigation__ul__li__dropdown__item__container'>
                    <Link to={'nom-235-se-2020'} className='navigation__ul__li__dropdown__item__container__title' >
                      NOM-235-SE-2020 - ATÚN Y BONITA
                    </Link>
                  </div>
                </li>
              </ul>
            </li>
            <li className='navigation__ul__li' onClick={() => setMobileItemOpen(3)}>
              Servicios
              <img src="/img/arrow-down.svg" alt="arrow" />
              <ul className='navigation__ul__li__dropdown'>
                <li className='navigation__ul__li__dropdown__item'>
                  <Link to={'constancy'} className='navigation__ul__li__dropdown__item__container__cta' >
                    <div className='navigation__ul__li__dropdown__item__container'>
                      <span className='navigation__ul__li__dropdown__item__container__title'>CONSTANCIA DE INSPECCIÓN</span>
                      <div className='navigation__ul__li__dropdown__item__container__separator'></div>
                      <p className='navigation__ul__li__dropdown__item__container__text'>
                        Documento emitido a los importadores que desean cumplir con el etiquetado antes de la importación. la mercancía deberá de etiquetar previo despacho aduanero. Teambíen es la opción ideal para los fabricantes nacionales que quieren cumplir antes de sacar su producto a la venta.
                      </p>
                    </div>
                  </Link>
                </li>
                <li className='navigation__ul__li__dropdown__item'>
                  <Link to={'tax-warehouse'} className='navigation__ul__li__dropdown__item__container__cta' >
                    <div className='navigation__ul__li__dropdown__item__container'>
                      <span className='navigation__ul__li__dropdown__item__container__title'>ETIQUETADO EN MÉXICO - ALMACEN FISCAL</span>
                      <div className='navigation__ul__li__dropdown__item__container__separator'></div>
                      <p className='navigation__ul__li__dropdown__item__container__text'>
                        Este servicio es para los importadores que desean etiquetar en México. Esta opción es ideal para importadores que van iniciando operaciones, Requiere contratar a un almacén general de depósito.
                      </p>
                    </div>
                  </Link>
                </li>
                <li className='navigation__ul__li__dropdown__item'>
                  <Link to={'private-warehouse'} className='navigation__ul__li__dropdown__item__container__cta' >
                    <div className='navigation__ul__li__dropdown__item__container'>
                      <span className='navigation__ul__li__dropdown__item__container__title'>ETIQUETADO EN MÉXICO - ALMACEN PARTICULAR</span>
                      <div className='navigation__ul__li__dropdown__item__container__separator'></div>
                      <p className='navigation__ul__li__dropdown__item__container__text'>
                        Esta es la opción para importadores que cuentan con más de 2 años en el padrón de importadores y han hecho 1 o varias operaciones de impoertación con un valor de mas de 100,000 USD. Por lo que podrán etiquetar en México y en almacén de su elección.
                      </p>
                    </div>
                  </Link>
                </li>
                <li className='navigation__ul__li__dropdown__item'>
                  <Link to={'revision-service'} className='navigation__ul__li__dropdown__item__container__cta' >
                    <div className='navigation__ul__li__dropdown__item__container'>
                      <span className='navigation__ul__li__dropdown__item__container__title'>REVISIÓN DE ETIQUETADO</span>
                      <div className='navigation__ul__li__dropdown__item__container__separator'></div>
                      <p className='navigation__ul__li__dropdown__item__container__text'>
                        Puedes solicitar la revisión de tu etiquetado en cualquier momento. Para corroborar que cumpla conlos requisitos de la normas.
                      </p>
                    </div>
                  </Link>
                </li>
              </ul>
            </li>
            <li className='navigation__ul__li'>
              <a href="https://blog.cecsatrade.mx/" target='_blank' rel="noreferrer">Blog</a>
            </li>
          </ul>
        </div>
        <Link to={'/'} className='navigation__logo-container' onClick={() => setMenuOpen(false)}>
          <img className='navigation__logo' src="/img/logo.svg" alt="CECSA Logo" />
        </Link>
        <div className='navigation__buttons-container'>
          <Button
            text={'Habla con un asesor'}
            onClick={() => { }}
            anchor={true}
            href='https://wa.me/message/YZR3TYWZRARDH1'
            target='_blank'
            type={'green'}
            icon='whatss-app.svg'
            iconClassName='navigation__buttons-container__logo'
            className='navigation__buttons-container__button'
            id='1'
          />
          {/* <Button
            text={'LogIn'}
            onClick={() => { }}
            type={'ligth'}
            anchor={true}
            href='https://app.cecsatrade.mx'
            target='_blank'
            className='navigation__buttons-container__button'
            id='2'
          /> */}
          <Button
            text={'Solicita una cotización'}
            onClick={() => setModalOpen(true)}
            type={'black'}
            className='navigation__buttons-container__button'
            id='3'
          />
          <button className='navigation__buttons-container__burguer-button' onClick={() => setMenuOpen(!menuOpen)}>
            {
              menuOpen ? (
                <img src="/img/cross.svg" alt="cross button" />
              ) : (
                <img src="/img/menu-burger.svg" alt="hamburger button" />
              )
            }
          </button>
        </div>
      </nav>
      <div className='layout'>
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default EmptyLayout;
