/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react';
import { BenefictsAndWhoDifferent, ExcusiveAttention, JsonLd, NormInfo, References, ServiceSteps, ServicesToOneClick } from '../../components';
import './styles.scss';
import { Helmet } from 'react-helmet';

const Nom139: React.FC = () => {
  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'NOM-139-SCFI-2012';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "@id": "https://www.cecsatrade.mx/nom-139-scfi-2012",
            "url": "https://www.cecsatrade.mx/nom-139-scfi-2012",
            "name": "NOM-139-SCFI-2012",
            "description": "Descubre los requisitos de la NOM-139-SCFI-2012 que regulan la producción, etiquetado y comercialización de la vainilla en México. Asegura la autenticidad y calidad de los extractos de vainilla natural y sintética con esta normativa.",
            "isPartOf": {
              "@id": "https://www.cecsatrade.mx/#/schema/WebSite/1"
            },
            "datePublished": "2023-08-17T08:08:40-06:00",
            "dateModified": "2023-08-17T08:43:03-06:00",
            "primaryImageOfPage": {
              "@id": "https://www.cecsatrade.mx/assets/139.svg"
            }
          }
        }
      />
      <Helmet>
        <meta
          name="description"
          content="Descubre los requisitos de la NOM-139-SCFI-2012 que regulan la producción, etiquetado y comercialización de la vainilla en México. Asegura la autenticidad y calidad de los extractos de vainilla natural y sintética con esta normativa."
        />
      </Helmet>
      <section className='norm-info'>
        <div className='sub-header'>
          <div className='sub-header__spacer'></div>
          <div className='sub-header__spacer'></div>
          {/* <a className='sub-header__button' href="https://blog.cecsatrade.mx/" target='_blank' rel="noreferrer">Blog</a> */}
          <a
            className='sub-header__email'
            href="mailto:asesoria@cecsatrade.mx"
            target="_blank">
            <img loading="lazy" src="/img/mail.svg" alt="Mail" />
            |
            Mail de CECSA
          </a>
        </div>
        <h1 className='norm-info__title'>NOM-139-SCFI-2012 VAINILLA</h1>
        <div className='norm-info__header norm-info__header--139'>
          <button onClick={() => {
            const element = document.getElementById("benefits");

            if (element) {
              element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            }
          }}>Beneficios</button><a href='https://wa.me/message/YZR3TYWZRARDH1' target='_blank'>Quiero cumplir con la NOM</a>
          <button onClick={() => {
            const element = document.getElementById("preguntas-frecuentes");

            if (element) {
              element.scrollIntoView({ behavior: "smooth", inline: "nearest" });
            }
          }}>Preguntas frecuentes</button>
        </div>
        <div className='norm-info__image-container'>
          {/* <img className='norm-info__image-container__img' loading="lazy" src="/img/nom-003-asset.svg" alt="NOM 003 Logo" /> */}
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Sé el primero en saber...</h2>
            <br />
            <p>
              La <b>Norma de extracto natural de vainilla (Vanilla spp.), derivados y sustitutos</b>, tiene como objeto establece la información comercial que debe exhibir la etiqueta de los productos dentro de la Nom 139 para la comercialización dentro del territorio de los Estados Unidos Mexicanos.
              <br />
              <br />
              Esta <b>Norma Oficial Mexicana</b> es aplicable y obligatoria  a los productos de fabricación nacional o extranjera que se comercializan en el territorio nacional.
            </p>
          </div>
        </div>
        <div className='norm-info__image-container norm-info__image-container--black'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center norm-info__image-container__text--small-margin'>
            <p style={{ textAlign: 'left' }}>
              Descubre los requisitos de la <b>NOM-139-SCFI-2012</b> que regulan la producción, etiquetado y <b>comercialización de la vainilla en México.</b> Asegura la autenticidad y calidad de los extractos de vainilla natural y sintética con esta normativa.
            </p>
          </div>
          <div className='norm-info__image-container__img-container'>
            <img className='norm-info__image-container__img-container__image' loading="lazy" src="/img/vainilla-1.png" alt="vainilla-1" />
          </div>
        </div>
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Entrada en vigor</h2>
            <br />
            <p>
              <b>NOM-139-SCFI-2012. Información comercial-Etiquetado de extracto natural de vainilla (Vanilla spp), derivados y sustitutos</b> fue publicada en el Diario Oficial de la Federación el 10 de julio de 2012,  pero entró en vigor 60 días después, es decir  a partir del 9 de septiembre de 2012.
            </p>
            <br />
            <a className='norm-info__image-container__anchorage' href="https://www.dof.gob.mx/nota_detalle.php?codigo=5269348&fecha=19/09/2012#gsc.tab=0" target='_blank'>Más información de esta NOM</a>
          </div>
        </div><img className='norm-info__ellipse' src="/img/ellipse-black.svg" alt="ellipse" />
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Aplicación de la NOM-139</h2>
            <br />
            <p>Su principal es asegurar que los productos que contienen vainilla o sus sustitutos proporcionen información clara, veraz y completa a los consumidores en México, garantizando así la calidad, autenticidad y trazabilidad del producto.</p>
            <br />
            <p>
              <div className='norm-info__image-container__aplications-015'>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-139-1.svg" alt="Esencia de vainilla " />
                  <span><b>Esencia de vainilla </b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-139-2.svg" alt="Extracto de vainilla " />
                  <span><b>Extracto de vainilla </b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-139-3.svg" alt="Vainilla y sus derivados " />
                  <span><b>Vainilla y sus derivados </b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-139-4.svg" alt="Saborizante de vainilla" />
                  <span><b>Saborizante de vainilla</b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-139-5.svg" alt="Aromatizante de vainilla" />
                  <span><b>Aromatizante de vainilla</b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-139-6.svg" alt="Vainilla en polvo" />
                  <span><b>Vainilla en polvo</b></span>
                </div>
              </div>
            </p>
          </div>
        </div>
        <ExcusiveAttention />
        <div className='norm-info__image-container norm-info__image-container--blue-050'>
          <img className='norm-info__image-container__image-background-1' loading="lazy" src="/img/background-circle-blue-1.svg" alt="backghround 1" />
          <div className='norm-info__image-container__text'>
            <h2>La NOM-139-SCFI-2012 no aplica a:</h2>
            <br />
            <p>La presente <b>Norma Oficial Mexicana</b> no es aplicable a lo siguiente:</p>
            <br />
            <div className='norm-info__image-container__text__data-item-container-004'>
              <div className='norm-info__image-container__text__data-item' style={{ flexDirection: 'column' }}>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/not-aplication-139-1.svg" alt="Cósmeticos con aroma a vainilla " />
                <span><b>Cósmeticos con aroma a vainilla </b></span>
              </div>
              <div className='norm-info__image-container__text__data-item' style={{ flexDirection: 'column' }}>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/not-aplication-139-2.svg" alt="Cremas con aroma a vainilla" />
                <span><b>Cremas con aroma a vainilla</b></span>
              </div>
              <div className='norm-info__image-container__text__data-item' style={{ flexDirection: 'column' }}>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/not-aplication-139-3.svg" alt="Postres con sabor a vainilla" />
                <span><b>Postres con sabor a vainilla</b></span>
              </div>
              <div className='norm-info__image-container__text__data-item' style={{ flexDirection: 'column' }}>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/not-aplication-139-4.svg" alt="Saborizantes artificiales" />
                <span><b>Saborizantes artificiales</b></span>
              </div>
            </div>
            <br />
          </div>
          <img className='norm-info__image-container__image-background-2' loading="lazy" src="/img/background-circle-blue-2.svg" alt="backghround 2" />
        </div>
        <div className='norm-info__image-container norm-info__image-container--black norm-info__image-container--margin norm-info__image-container--050' style={{ marginBottom: '30rem', overflow: 'visible', zIndex: '7' }}>
          <h2>DEFINICIONES</h2>
          <div className="norm-info__050-definitions__item">
            <span>Extracto natural de vainilla</span>
            <p>Solución hidroalcohólica de vaina de vainilla beneficiada, de color ámbar, obtenida a través de procesos como maceración, percolación o filtración.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Oleorresinas de vainilla</span>
            <p>Producto obtenido de la vainilla beneficiada mediante extracción con solventes, que se eliminan parcial o totalmente, perdiendo algunos aromas en el proceso.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Vainilla beneficiada o curada</span>
            <p>Fruto procesado que ha desarrollado el aroma, color y sabor propios de la vainilla gracias a sus enzimas.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Vainilla en polvo o Polvo vainillado</span>
            <p>Fruto que ha pasado por un proceso de beneficiado, desarrollando el aroma, color y sabor característicos de la vainilla gracias a la acción de sus enzimas.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span> Vainillina</span>
            <p>Principal componente aromático de la vainilla, cuyo nombre químico corresponde a 3-metoxi-4-hidroxibenzaldehído (vainillina).</p>
          </div>
          <h2>CONTENIDO DE LA ETIQUETA</h2>
          <br />
          <br />
          <p>Las etiquetas deben incluir información clara sobre el contenido, origen y características del producto en español, con opción de agregar otros idiomas si se desea.</p>
          <br />
          <h2>Denominación Comercial</h2>
          <div className="norm-info__015-definitions__item norm-info__015-definitions__item--column">
            <div className="norm-info__015-definitions__item__container">
              <p>
                <br />
                <b>Extracto Natural Concentrado</b>
                <br />
                "VAINILLA, EXTRACTO NATURAL CONCENTRADO"
                <br />
                <br />
                <b>Extracto Natural</b>
                <br />
                "VAINILLA, EXTRACTO NATURAL"
                <br />
                <br />
                <b>Polvo de Vainilla</b>
                <br />
                "VAINILLA EN POLVO"
              </p>
            </div>
            <img src="/img/vainilla-2.png" alt="vainilla-2" />
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Saborizante Natural</span>
            <p>"VAINILLA, SABORIZANTE NATURAL"</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Polvo de Vainilla</span>
            <p>"VAINILLA EN POLVO"</p>
          </div>
          <br />
          <br />
          <h2>Información Comercial</h2>
          <br />
          <br />
          <p style={{ textAlign: 'center' }}>El nombre específico del producto debe aparecer claramente y tener al menos el 80% del tamaño del nombre genérico.</p>
          <br />
          <br />
          <div className="norm-info__015-definitions__item norm-info__015-definitions__item--images">
            <img src="/img/139-logo-1.svg" alt="139-logo-1" />
            <img src="/img/139-logo-2.svg" alt="139-logo-2" />
          </div>
          <br />
          <p style={{ textAlign: 'center' }}>Este etiquetado garantiza que el producto de vainilla se clasifique y nombre correctamente, proporcionando información clara y veraz al consumidor.</p>
          <img className='norm-info__050-definitions__footer' loading="lazy" src="/img/definitions-050-footer.svg" alt="footer" />
        </div>

        <BenefictsAndWhoDifferent
          title={'CERTIFICA TUS PRODUCTOS'}
          text={'Contactanos, nosotros te apoyamos en cada paso que debes realizar para cumplir conforme lo establecido de manera nacional en materia de comercial dentro de la industria.'}
        />
        <References
          items={[
            (
              <li>
                <img src="/img/list-item-139.png" alt="atun" />
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=718870&fecha=27/11/2002#gsc.tab=0" target='_blank'>NOM-008-SCFI-2002</a>, Sistema General de Unidades de Medida.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-139.png" alt="atun" />
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=4936602&fecha=06/11/2006#gsc.tab=0" target='_blank'>NOM-030-SCFI-2006</a>, Información comercial-Declaración de cantidad en la etiqueta-Especificaciones.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-139.png" alt="atun" />
                <a href="https://www.dof.gob.mx/normasOficiales/4010/seeco11_C/seeco11_C.htm" target='_blank'>NOM-051-SCFI/SSA1-2010</a>, Especificaciones generales de etiquetado para alimentos y bebidas no alcohólicas preenvasados-Información comercial y sanitaria.
              </li>
            )
          ]}
        />
        <NormInfo
          DOFDate='10/07/2012'
          VigorDate='09/09/2012'
          responsible='Secretaría de Economía'
        />
        <div className='norm-info__questions' id='preguntas-frecuentes'>
          <h2 className="norm-info__container__sub-title">Preguntas frecuentes</h2>
          <ul className="accordion norm-info__container__not-transform-ul" id="accordion">
            <li className="accordion-item">
              <div id="heading1">
                <button className="accordion-button norm-info__container__accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                  ¿Cuál es la diferencia entre extracto natural de vainilla y sus sustitutos?
                </button>
              </div>
              <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordion">
                <div className="accordion-body">
                  La diferencia entre el extracto natural de vainilla y sus sustitutos radica principalmente en el origen y la composición del sabor a vainilla.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading2">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                  ¿Se puede etiquetar como “vainilla” un producto que contiene saborizantes artificiales?
                </button>
              </div>
              <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordion">
                <div className="accordion-body">
                  No. Si el producto contiene saborizantes artificiales o derivados sintéticos de la vainilla, debe indicarse claramente como un sustituto y no puede utilizar la palabra "vainilla" sin esta aclaración.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading3">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                  ¿Qué ocurre si un producto no cumple con los requisitos de la NOM-139?
                </button>
              </div>
              <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Las empresas que no cumplan con los requisitos de etiquetado pueden enfrentar sanciones por parte de las autoridades mexicanas, como la PROFECO o la Secretaría de Economía. Las sanciones pueden incluir multas, el retiro del producto del mercado, e incluso la suspensión temporal de actividades.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading4">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                  ¿La NOM-139 también se aplica a productos de exportación?
                </button>
              </div>
              <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Sí, los productos que se fabrican en México para ser exportados también deben cumplir con los requisitos de la NOM-139, siempre que el destino final del producto no tenga regulaciones diferentes que deban cumplirse.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading5">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                  ¿Es obligatorio declarar la concentración de vainillina en la etiqueta?
                </button>
              </div>
              <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Sí, la concentración de vainillina debe especificarse en los productos que utilicen extracto natural de vainilla, ya que es uno de los componentes clave que determina la autenticidad y calidad del producto.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading6">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                  ¿Qué pasa si detienen mi producto en aduana?
                </button>
              </div>
              <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordion">
                <div className="accordion-body">
                  En primer lugar, deberás de solicitar la fracción arancelaria del producto. Una vez que tengas la fracción arancelaria y corrobores que le solicitan el cumplimiento de la NOM-139-SCFI-2012 deberás de contactar a una Unidad de Inspección para obtener una Constancia de Inspección.
                  <br />Debido a que el producto no puede etiquetarse en México, deberás de etiquetar en la aduana o regresar el producto con tu proveedor para que sea etiquetado.
                  <br />Te recomendamos analizar los costos de cada situación y evaluarlos.
                  <br />
                  <br />
                  Una vez que nos contactes, te brindaremos la asesoría y apoyo para que puedas liberar tu producto de la aduana. <br />El proceso será rápido, cada minuto cuenta, por lo que te dejamos nuestro correo (contacto@cecsatrade.mx) y número de contacto (56 5019 0728)
                </div>
              </div>
            </li>
          </ul>
        </div>

        <img className='norm-info__ellipse' src="/img/ellipse-blue.svg" alt="ellipse" />
        <ServicesToOneClick />
        <ServiceSteps
          withNewsLetter={true}
        />
      </section>
    </>
  )
}

export default Nom139